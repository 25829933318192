import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import { Helmet } from 'react-helmet';
import { Heading2 } from '../../atoms/Headings/Headings';
import { generateButtonCss } from '../../atoms/Buttons/Button/Button';
import { checkboxCss } from '../../atoms/FormFields/CheckBox/CheckBox';
import {
  breakpoints,
  colors,
  durations,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  shadows,
  unitConverter as uc,
} from '../../../styles/base';

const formCardCss = css`
  margin: ${uc('-10px auto auto')};
  padding: ${uc('30px 30px 1px 50px')};
  font-size: ${fontSizes.twenty};
  text-align: center;
  background: ${colors.white};
  border-radius: ${uc('50px')};
  box-shadow: ${shadows.large};
`;

const formCss = css`
  form {
    /* TODO: make width/height dynamic and then animate to real form size */
    width: ${uc('845px')};
    height: ${uc('605px')};
    opacity: 0;

    &.marketo-styles-removed {
      width: auto;
      height: auto;
      opacity: 1;
    }
  }

  .mktoForm {
    * {
      font-family: ${fontFamilies.proximaSoft};
    }

    .mktoLabel {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      font-weight: ${fontWeights.bold};

      .mktoAsterix {
        color: ${colors.accent};
      }

      @media (${breakpoints.smallDesktopMax}) {
        margin-bottom: ${uc('10px')};
      }
    }
  }

  button {
    ${generateButtonCss('accent')}
  }

  .mktoFieldWrap {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: ${uc('15px auto')};
    color: ${colors.darkGray.two};
    font-weight: ${fontWeights.bold};

    select {
      font: inherit;
      line-height: ${uc('21px')};
      background-color: ${colors.lightGray.one};
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 72.22 42.46' style='enable-background:new 0 0 72.22 42.46;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%234E5969;%7D%0A%3C/style%3E%3Cpath class='st0' d='M35.38,42.36c0.25,0.06,0.49,0.11,0.74,0.11c0.25,0,0.48-0.05,0.73-0.11c0.24-0.06,0.48-0.11,0.71-0.23 c0.24-0.12,0.46-0.28,0.68-0.46c0.14-0.11,0.3-0.16,0.43-0.29L70.88,8.13c1.63-1.68,1.79-4.6,0.38-6.53 c-1.42-1.93-3.88-2.13-5.51-0.46L36.11,31.75L6.46,1.14C4.83-0.54,2.37-0.34,0.96,1.59C0.32,2.47,0,3.55,0,4.63 c0,1.29,0.45,2.58,1.34,3.5l32.21,33.25c0.13,0.14,0.29,0.18,0.43,0.29c0.22,0.18,0.44,0.35,0.68,0.46 C34.9,42.24,35.13,42.3,35.38,42.36z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: 93% center;
      background-size: 4%;
      appearance: none;
    }

    input {
      background: ${colors.lightGray.one};
    }

    select,
    input {
      align-self: normal;
      box-sizing: border-box;
      width: ${uc('400px')};
      min-height: ${uc('50px')};

      padding: ${uc('0 35px')};
      color: ${colors.darkGray.two};
      border: ${uc('1px')} solid ${colors.transparent};
      border-radius: ${uc('10px')};

      &:focus,
      &:invalid:focus {
        border: ${uc('1px')} solid ${colors.primary};
        outline: none;
      }

      @media (${breakpoints.mobile}) {
        width: 100%;
      }
    }

    select:not(:focus):invalid {
      color: ${colors.lightGray.four};
    }

    .mktoCheckboxList,
    .mktoRadioList {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 2.5rem 1fr;
      align-items: center;
      width: 50%;
      margin: ${uc('20px -20px')};
      justify-items: left;

      label {
        line-height: ${lineHeights.title};
        text-align: left;
      }
    }

    .mktoCheckboxList {
      [type='checkbox'] {
        ${checkboxCss}
      }
    }

    /* the privacy policy */
    .mktoHtmlText {
      font-size: ${fontSizes.twelve};
      font-family: ${fontFamilies.roboto};
    }
  }

  .mktoInvalid {
    border: 1px solid ${colors.accent};
  }

  .mktoError {
    position: absolute;

    /* this value is set by js in marketo, but we need to override for responsive */
    bottom: calc(-100% + ${uc('32px')}) !important;
    z-index: 2;
    padding: ${uc('10px')};
    color: ${colors.accent};
    line-height: ${lineHeights.title};
    background: white;
    border-radius: ${uc('5px')};
    box-shadow: ${shadows.small};
    transform: translateY(${uc('25px')});
  }

  .mktoErrorArrowWrap {
    position: absolute;
    top: 0;
    left: 50%;
  }

  .mktoErrorArrow {
    position: absolute;
    z-index: -1;
    width: 20px;
    height: 20px;
    background: white;
    transform: rotate(45deg) translateY(${uc('-10px')});
  }

  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type='radio'] {
      position: relative;
      width: ${uc('45px')};
      height: ${uc('45px')};
      min-height: auto;
      background: transparent;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: ${uc('35px')};
        height: ${uc('35px')};
        margin: auto;
        background: ${colors.lightGray.one};
        border-radius: 50%;
        box-shadow: inset 0 0 0 ${uc('2px')} ${colors.lightGray.two};
        transition: ${durations.fast};
        content: '';
      }

      &:focus {
        outline: none;

        &::after {
          outline: 1px solid ${colors.primary};
        }
      }

      &:checked {
        &::after {
          background: ${colors.white};
          box-shadow: inset 0 0 0 0.4em gray;
        }
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.9;
      }

      & + label {
        cursor: pointer;
      }

      &:disabled + label {
        cursor: not-allowed;
      }
    }
  }

  /* Date Selector is cropping yyyy */
  [type='date'] {
    line-height: ${lineHeights.title};
  }

  textarea {
    line-height: ${lineHeights.title};
  }
`;

const destyleMktoForm = mktoForm => {
  const formEl = mktoForm.getFormElem()[0];

  // remove element styles from <form> and children
  const styleElementsToStrip = Array.from(
    formEl.querySelectorAll('[style]')
  ).concat(formEl);
  styleElementsToStrip.forEach(el => {
    el.removeAttribute('style');
  });

  // disable remote stylesheets and local <style>s
  const styleSheets = Array.from(document.styleSheets);
  const styleSheetsToRemove = ['mktoForms2ThemeStyle', 'mktoForms2BaseStyle'];
  styleSheets.forEach(ss => {
    if (
      styleSheetsToRemove.includes(ss.ownerNode.id) ||
      formEl.contains(ss.ownerNode)
    ) {
      ss.disabled = true; // eslint-disable-line
    }
  });

  // remove the CSS elements from the form so we can use flexbox
  const cssElementsToStrip = Array.from(
    formEl.querySelectorAll('.mktoGutter, .mktoClear')
  );
  cssElementsToStrip.forEach(el => {
    el.remove();
  });

  // TODO: find and tag privacy policy so we can style it

  // add class so we can display the form after the modifications are made
  formEl.classList.add('marketo-styles-removed');
};

const MarketoEmbeddedForm = ({
  className,
  marketoFormId,
  thankYouPage,
  title,
}) => {
  const forceThankYouPage = () => {
    window.location.href = thankYouPage.route.slug.current;
    return false;
  };

  const marketoFormCallbacks = form => {
    destyleMktoForm(form);
    form.onSuccess(forceThankYouPage);
  };

  const [firstBuild, setFirstBuild] = useState(true);

  let attempts = 0;
  const loadMarketoForm = () => {
    if (attempts > 2) {
      return;
    }

    /* global MktoForms2 */
    if (typeof MktoForms2 === 'undefined') {
      attempts += 1;
      setTimeout(() => {
        loadMarketoForm();
      }, 1000);
    } else {
      MktoForms2.loadForm(
        '//app-abd.marketo.com',
        '076-PDR-714',
        marketoFormId,
        marketoFormCallbacks
      );
    }
  };

  useEffect(() => {
    if (firstBuild) {
      loadMarketoForm();
      setFirstBuild(false);
    }
  }, []);

  return (
    <div css={formCardCss} className={className}>
      <Helmet>
        <script src="//app-abd.marketo.com/js/forms2/js/forms2.min.js" />
        <script type="text/javascript">
          {`
          /* this script is currently loaded via GTM, but I'm adding here to be safe */
        (function() {
          var didInit = false;
          function initMunchkin() {
          if(didInit === false) {
          didInit = true;
          Munchkin.init('076-PDR-714');
        }
        }
          var s = document.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = '//munchkin.marketo.net/munchkin.js';
          s.onreadystatechange = function() {
          if (this.readyState == 'complete' || this.readyState == 'loaded') {
          initMunchkin();
        }
        };
          s.onload = initMunchkin;
          document.getElementsByTagName('head')[0].appendChild(s);
        })();
        `}
        </script>
      </Helmet>
      {title && <Heading2>{title}</Heading2>}
      <div css={formCss}>
        <form id={`mktoForm_${marketoFormId}`} />
      </div>
    </div>
  );
};

MarketoEmbeddedForm.propTypes = {
  className: PropTypes.string,
  marketoFormId: PropTypes.string.isRequired,
  title: PropTypes.string,
  thankYouPage: PropTypes.shape({ route: PropTypes.instanceOf(Object) }),
};

MarketoEmbeddedForm.defaultProps = {
  className: '',
  title: '',
  thankYouPage: { route: { slug: { current: '/thank-you' } } },
};

export default MarketoEmbeddedForm;
